<template>
  <div>
    <Breadcrumb
      :home="{ icon: 'pi pi-home', to: '/admin' }"
      :model="BreadcrumbItems"
    />

    <Toolbar class="p-mb-4 mt-2">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/system/slider')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="image" class="form-label">
          الصوره
        </label>
        <img
          :src="body.image"
          v-if="body.image"
          class="profile-pic height-150 width-150 align-items-center"
          style="height: 100px;"
        />
        <div class="">
          <i class="ri-pencil-line upload-button"></i>
          <b-form-file
            class="h-100 "
            accept="image/*"
            @change="previewImage"
          ></b-form-file>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
        />
        <Button
          v-else
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      BreadcrumbItems: [
        {
          label: 'السلايدر',
          to: '/admin/system/slider/',
        },
        { label: 'اضافه' },
      ],
      body: {
        image: null,
      },
      id: null,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },

    save() {
      if (this.body.image) {
        this.$http.post(`slider`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/system/slider');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.image) {
        if (this.body.image.startsWith('http')) {
          delete this.body.image;
        }
        this.$http.put(`slider/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/system/slider');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`slider/${this.id}`).then(
        (res) => {
          this.body = res.data.data;

          this.body.image = this.$baseUploadURL + this.body.image;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>
